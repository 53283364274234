import ajax from '../common/ajax'
import Api from './index.d'
import { Toast } from '@nutui/nutui'

// 设置错误提示信息
ajax.setTipFn((tips: string) => {
  Toast.text(tips)
})

const rest: Api = {
  auth: {
    isLogin: ajax.query('/auth/is_login'),
    login: ajax.query('/auth/boss_login_url')
  },
  wx: {
    // 获取config配置
    getWeChatConfigResource: ajax.query('/operation_management/qiye_jsapi_config'),
    // 获取agentConfig配置
    getWeChatAgentConfigResource: ajax.query('/operation_management/agent_jsapi_config')
  },
  dispute: {
    // 获取运营没创建流程的审议单列表
    getDisputeApplyList: ajax.query('/operation_management/dispute/apply_list'),
    // 获取争议单详情
    getDisputeOrderDetail: ajax.query('/operation_management/dispute/support_store_order_detail')
  }
}

export default rest
