/**
 * rest 请求配置
 * process.env.PROJECT_VERSION 需要在构造文件 vue.config.js 中配置
 * process.env.BUILD_ENV 需要在构建配置中配置 vue.config.js 中配置
 */
export default {
  dsn: 'https://c3392ee80c8d44658ebe640426910780@sentry.lixinio.com/469',
  release: process.env.PROJECT_VERSION,
  logger: 'javascript',
  ignoreUrls: [/sentry\.lixinio\.com/, /hm\.baidu\.com/, /map\.qq\.com/],
  environment: process.env.BUILD_ENV,
  sechame: ''
}
