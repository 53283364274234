import Wechat from '../plugins/wechat'
import config from './config'

const wechat = new Wechat(config)

export default {
  wechat,
  install: (app) => {
    app.use(Wechat, {
      wechat
    })
  }
}
