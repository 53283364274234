import { createSchemaWithProperties } from '@lx-frontend/router-params-validator'

export default {
  '/dispute/disputeApplyList': {
    '/': createSchemaWithProperties([
      { name: 'jwt_token', required: false }
    ]),
    // 回退
    '/dispute/disputeDetail/:orderId': createSchemaWithProperties([
      { name: 'jwt_token', required: false }
    ]),
    // 缺失jwt_token重定向后回退
    '/dispute/disputeApplyList': createSchemaWithProperties([
      { name: 'jwt_token', required: false }
    ])
  }
}
