/**
 *
 */
import './jweixin-1.0.1'
export default class Wechat {
  // @ts-ignore
  static install () {}
    // 校验微信接口
  constructor(options) {
    this.wx = window.wx
    this.flag = window.DEBUG // DEBUG 状态下设为true
    this.res = null // 微信返回的校验成功的结果
    this.location = {
      latitude: 0,
      longitude: 0
    }
    // 需要检测的JS接口列表，所有JS接口列表见附录2
    this.jsApiList = []

    // merge 方法
    Object.assign(this, options)
    if (this.beforeCreate) {
      this.beforeCreate()
    }

    this.checkWXConfig()

    // 构造函数执行完之后的hook
    if (this.created) {
      this.created()
    }
  }

  checkWXConfig () {
    this.promise = new Promise((resolve, reject) => {
      if (this.flag) {
        this.ready(resolve)
      } else {
        if (!this.getWechatConfigResource) {
          throw new Error('no defined getWechatConfigResource function')
        }
        this.getWechatConfigResource().then((res) => {
          if (res.code !== 0) return
          let data = res.data
          // 企业微信 config
          this.wx.config({
            beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名
            jsApiList: this.jsApiList
          })


          this.wx.ready(() => {
            // 注意：wx.agentConfig 方法 需要在 wx.config 完成之后才能调用。
            // 获取企业微信 agentConfig 配置信息
            this.getWeChatAgentConfigResource().then((agentRes) => {
              if (agentRes.code !== 0) return
              let agentConfigData = agentRes.data
              // 调用企业微信 js-sdk agentConfig
              this.wx.agentConfig({
                corpid: agentConfigData.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                agentid: agentConfigData.agentid, // 必填，企业微信的应用id
                timestamp: agentConfigData.timestamp, // 必填，生成签名的时间戳
                nonceStr: agentConfigData.nonceStr, // 必填，生成签名的随机串
                signature: agentConfigData.signature, // 必填，签名，见附录1
                jsApiList: ['thirdPartyOpenPage'], //必填
                fail: function (res) {
                  alert(res.errMsg)
                  if (res.errMsg.indexOf('function not exist') > -1) {
                    alert('版本过低请升级')
                  }
                }
              });
            })


            // 判断当前客户端版本是否支持指定JS接口
            this.wx.checkJsApi({
              jsApiList: this.jsApiList,
              success: (res) => {
                this.flag = true
                this.res = res
                resolve(this)
                this.ready(resolve)
              },
              error: (res) => {
                this.flag = false
                this.res = res
                reject(this)
                throw new Error('checkJsApi 失败')
              }
            })
          })
          this.wx.error((res) => {
            this.flag = false
            this.res = res
            reject(this)
            throw new Error('wxjssdk 注册失败')
          })
        }, (res) => {
          this.res = res
          reject(this)
          throw new Error('wxjssdk 获取配置信息时报')
        })
      }
    })
  }

  setFlag (val) {
    this.flag = val
  }

  // sentry issues#30
  ready (resolve) {
    let onBridgeReady = () => {
      resolve(this)
    }
    if (typeof window.WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
      }
    } else {
      onBridgeReady()
    }
  }
}

/**
 * vue 组件安装方法
 * @return {[type]}
 */
function install (app, options) {
  if (install.installed) return
  install.installed = true
  app.mixin({
    beforeCreate () {
      if (options.wechat) {
        app.config.globalProperties.$wechat = options.wechat
      } else if (options.parent && options.parent.$wechat) {
        app.config.globalProperties.$wechat = options.parent.$wechat
      }
    }
  })
}
Wechat.install = install
Wechat.version = '__VERSION__'
