import { createSchemaWithProperties } from '@lx-frontend/router-params-validator'

export default {
  '/dispute/disputeDetail/:orderId': {
    '/dispute/disputeApplyList': createSchemaWithProperties([
      { name: 'orderId', required: true },
      { name: 'jwt_token', required: false }
    ])
  }
}
