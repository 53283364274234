/**
 * 数据上报
 */
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import config from './config'

export default {
  install: (app: any, option: any) => {
    // 构建环境为开发环境和测试环境时，sentry关闭上报
    if (config.environment === 'production') {
      Sentry.init({
        ...config,
        app,
        integrations: [new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(option.router)
        })],
        // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. We recommend adjusting this value in production
        tracesSampleRate: 1.0
      })
      app.config.globalProperties.$report = Sentry
    }

    // 测试sentry是否正常配置
    if (Sentry.captureMessage !== undefined && config.environment === 'production' && process.env.SENTRY_TEST) {
      Sentry.captureMessage('test~')
    }
  },
}

export {
  Sentry as report
}
