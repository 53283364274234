import { createApp } from 'vue'
import App from '@/App.vue'
import nutuiImport from './common/nutuiImport'
// 初始化nutui样式
import "@nutui/nutui/dist/style.css"
import report from '@/report'
import rest from '@/rest'
import router from '@/router'
import store from '@/store'
// 微信SDK调用
import wechat from './wechat'
// 使用 flexible 适配
import './common/flexible'

// 构建环境为非正式环境开始debug模式
if (process.env.BUILD_ENV !== 'production') {
  import('@/debug')
  import('@/mock/index')
}

// 样式文件
import './assets/styles/app.less'

const app = createApp(App)

// 按需加载nutui组件
Object.values(nutuiImport).forEach(tui => {
  // @ts-ignore
  app.use(tui)
})

app
.use(report, { router })
.use(store)
.use(router)
.use(wechat)
.use(rest)
.mount('#app')
