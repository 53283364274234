import { RouterOptions, createWebHistory } from 'vue-router'

const config: RouterOptions = {
  // 使用 history 模式，需要 nginx 做相关配置。
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  routes: [
    { // 默认打开页面
      path: '/',
      redirect: '/dispute/disputeApplyList'
    },
    {
      path: '/dispute/disputeApplyList',
      name: 'disputeApplyList',
      meta: {
        title: '争议单列表'
      },
      component: () => import('../pages/dispute/DisputeApplyList.vue')
    },
    {
      path: '/dispute/disputeDetail/:orderId',
      name: 'disputeDetail',
      meta: {
        title: '争议单详情'
      },
      component: () => import('../pages/dispute/DisputeDetail.vue')
    },
    {
      path: '/dispute/disputeImage/:orderId',
      name: 'disputeImage',
      meta: {
        title: '争议单图片'
      },
      component: () => import('../pages/dispute/DisputeImage.vue')
    }
  ]
}

export default config
