/**
 * mixins to Wechat Class
 */
// 缓存处理
import {restConfig as rest} from '../rest'
export default {
  jsApiList: ['agentConfig', 'chooseWXPay', 'openLocation', 'scanQRCode', 'chooseImage', 'onMenuShareAppMessage', 'onMenuShareTimeline', 'uploadImage', 'startRecord', 'onVoiceRecordEnd', 'stopRecord', 'closeWindow', 'previewImage', 'thirdPartyOpenPage'],
  // 获取微信签名信息
  getWechatConfigResource() {
    return rest.wx.getWeChatConfigResource({
      url: window.encodeURIComponent(window.location.href.split('#')[0])
    })
  },
  // 获取 agentConfig 配置
  getWeChatAgentConfigResource() {
    return rest.wx.getWeChatAgentConfigResource({
      url: window.encodeURIComponent(window.location.href.split('#')[0])
    })
  },
  payByWx(appId, timeStamp, nonceStr, packageStr, signType, paySign, success, cancel, fail) {
    let config = {
      appId: appId, // 公众号名称，由商户传入
      timeStamp: timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
      package: packageStr, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
      signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
      paySign: paySign // 支付签名
    }
    window.WeixinJSBridge.invoke('getBrandWCPayRequest', config, res => {
      if (res.err_msg === 'get_brand_wxpay_request:ok' && success) {
        success(res)
      } else if (res.err_msg === 'get_brand_wxpay_request:cancel' && cancel) {
        cancel(res)
      } else if (res.err_msg === 'get_brand_wxpay_request:fail' && fail) {
        fail(res)
      }
    })
  },
  /**
   * 扫一扫
   */
  scanQRCode(callback) {
    this.wx.scanQRCode({
      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
      success: callback
    })
  },
  // 位置相关的微信接口
  openLocationByWx(latitude, longitude, name, address, scale, infoUrl) {
    this.wx.openLocation({
      latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
      longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
      name: name, // 位置名
      address: address, // 地址详情说明
      scale: scale, // 地图缩放级别,整形值,范围从1~28。默认为最大
      infoUrl: infoUrl // 在查看位置界面底部显示的超链接,可点击跳转
    })
  },
  getLocationByWx(type) {
    type = type || 'gcj02'
    let promise = new Promise((resolve, reject) => {
      // 获取地址
      this.wx.getLocation({
        type: type, // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: (res) => {
          // res.latitude // 纬度，浮点数，范围为90 ~ -90
          // res.longitude // 纬度，浮点数，范围为90 ~ -90
          resolve(res)
        },
        cancel: (res) => {
          reject(res)
        }
      })
    })
    return promise
  },
  // 分享到朋友圈
  shareTimeline(data) {
    this.wx.onMenuShareTimeline(data)
  },
  // 分享给朋友
  shareAppMessage(data) {
    this.wx.onMenuShareAppMessage(data)
  },
  hideOptionMenu() {
    this.wx.hideOptionMenu()
  },
  hideMenuItems(options) {
    let menuItems = options && options.menuItems || [
      'menuItem:share:appMessage',
      'menuItem:share:timeline',
      'menuItem:share:qq',
      'menuItem:share:weiboApp',
      'menuItem:share:favorite'
    ]
    this.wx.hideMenuItems({
      menuList: menuItems,
      success: res => {
        // console.log('hide success')
        options && options.success && options.success()
      },
      fail: res => {
        // console.log('hide fail')
        options && options.fail && options.fail()
      }
    })
  },
  batchUploadImage(num, callback) {
    let self = this.wx
    let images = { localIds: [], serverId: [] }
    if (num >= 9) num = 9
    this.wx.chooseImage({
      count: num,
      sizeType: ['original', 'compressed'],
      sourceType: ['album', 'camera'],
      success: function(res) {
        images.localIds = res.localIds
        let i = 0
        let length = images.localIds.length
        let timer = null
        let upload = function() {
          self.uploadImage({
            localId: images.localIds[i],
            success: function(res) {
              images.serverId.push(res.serverId)
              // 如果还有照片，继续上传
              i++
              if (i < length) {
                clearTimeout(timer)
                timer = setTimeout(() => {
                  upload()
                }, 50)
              } else {
                timer = null
                callback(images.serverId)
              }
            }
          })
        }
        upload()
      }
    })
  },
  uploadImage(callback) {
    let self = this.wx
    this.wx.chooseImage({
      count: 1,
      sizeType: ['original', 'compressed'],
      sourceType: ['album', 'camera'],
      success: function(res) {
        let localId = res.localIds[0]
        if (localId === undefined) {
          return false
        }
        self.uploadImage({
          localId: localId,
          isShowProgressTips: 1,
          success: callback
        })
      }
    })
  },
  startRecord(success, fail, complete) {
    this.wx.startRecord({
      success,
      fail,
      complete
    })
  },
  onVoiceRecordEnd(complete) {
    this.wx.onVoiceRecordEnd({
      complete
    })
  },
  stopRecord(success, fail, complete) {
    this.wx.stopRecord({
      success,
      fail,
      complete
    })
  },
  /**
   * 关闭窗口
   */
  closeWindow() {
    this.wx.closeWindow()
  }
}
