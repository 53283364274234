export default {
  changeTitle (title: string) {
    document.title = title
    const iframe = document.createElement('iframe')
    iframe.src = '/favicon.ico'
    iframe.style.display = 'none'
    iframe.style.width = '1px'
    iframe.style.height = '1px'
    iframe.onload = function () {
      setTimeout(function () {
        document.body.removeChild(iframe)
      }, 0)
    }
    document.body.appendChild(iframe)
  },

  /**
   * isProductionEnv() 函数用于判断当前访问的域名是正式环境还是测试环境
   * return { boolean } true 正式环境/测试环境
   */
  isProductionEnv () {
    // 线上环境运行域名
    return 'fa.prod.dos.cheanjia.net' === window.location.host
  }
}
