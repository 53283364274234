import restConfig from './config'

export {
  restConfig
}

export default{
  install: (app: any) => {
    app.config.globalProperties.$rest = restConfig
  }
}
