import { createRouter } from 'vue-router'
import routeConfig from './config'
import util from '@/common/util'
import { Toast } from '@nutui/nutui'
import validator from '@/rules/index'
import { report } from '@/report/index'

const router = createRouter(routeConfig)

let routerToast: any = null

router.beforeEach((to, from, next) => {
  // 校验路由
  try {
    const routeValidateRes = validator.validate(from.path, to.path, to.params)
    if(routeValidateRes) {
      throw new Error(routeValidateRes.message.join(';'))
    }
  } catch (e) {
    // 上报
    if(report.captureException && process.env.BUILD_ENV === 'production') {
      report.captureException(e)
    }
    console.error(e)
  }
  const meta = to.meta || {}
  let titleKey: any = ''
  if (meta.titles && Object.keys(meta.titles).find) {
    titleKey = Object.keys(meta.titles).find((val) => {
      return to.path.endsWith(val)
    })
  }
  // 路由变化，修改title
  if (meta && meta.title) {
    const title = ((meta.titles && meta.titles[titleKey as keyof typeof meta.titles]) || meta.title) as any
    util.changeTitle(title)
  }
  // loading 动画
  if (meta && meta.needLoading) {
    routerToast = Toast.loading('加载中...')
  }
  next()
})

// 页面渲染完毕后关闭loading
router.afterEach(function (to) {
  // 判断是否需要展示loading界面
  if (to.meta && to.meta.needLoading) {
    const timer = window.setTimeout(() => {
      if (routerToast) {
        routerToast.hide()
        routerToast = null
      }
      clearTimeout(timer)
    }, 1000)
  }
})

export default router
