/**
 * ajax request help
 */
 import Ajv from 'ajv'
import axios, { AxiosRequestConfig, AxiosResponse, AxiosStatic } from 'axios'
import cache from './cache'
import cookie from './cookie'
import { restConfig as rest } from '../rest'

class Ajax {
  public $http: AxiosStatic
  private baseUrl: string
  private authUrl: string
  private isLogin: boolean
  private logining: boolean // 登录验证中
  private next: string // 下一步跳转路径
  private ajv: Ajv
  private queryMap: { [index: string]: queryCallback }
  private createMap: { [index: string]: createCallback }
  private putWayMap: { [index: string]: putCallback }
  private patchMap: { [index: string]: patchCallback }
  private deleteMap: { [index: string]: deleteCallback }
  private success: (res: AxiosResponse) => void
  private showTipsFn: (tips: string) => void

  constructor (options: {baseUrl?: string, authUrl?: string, success?: (res: AxiosResponse) => void} = {}) {
    this.$http = axios
    this.baseUrl = options && options.baseUrl ? options.baseUrl : '/api/v1'
    this.authUrl = options && options.authUrl ? options.authUrl : 'replacing_auth'
    // this.report = report
    // this.$store = store // vuex store
    this.isLogin = false
    this.queryMap = {}
    this.createMap = {}
    this.putWayMap = {}
    this.patchMap = {}
    this.deleteMap = {}
    // 正在登陆
    this.logining = false
    this.next = ''
    // ajv校验实例，默认只校验一个地错误
    this.ajv = new Ajv()
    // 通用拦截器
    if (options && typeof options.success === 'function') {
      this.success = options.success
    } else {
      this.success = () => undefined
    }
    this.showTipsFn = () => undefined

    // request 请求拦截器
    // 给POST请求头加上x-crsf-token
    axios.interceptors.request.use(
      (config) => {
        const csrftoken = cookie.getCookie('csrf-token')
        const jwtToken = cache.getSessionData('jwt_token')
        if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(config.method + '')) {
          config.headers.common['X-CSRF-Token'] = csrftoken
        }
        config.headers.common['JWT-TOKEN'] = jwtToken
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )

    // ajax 全局错误处理
    axios.interceptors.response.use(
      (response: AxiosResponse) => {
        // 调用校验方法，现阶段先只校验和上报错误，暂不做数据修改返回自定义错误
        // this.validateResponse(response);
        this.success(response)
        return response
      },
      (err) => {
        if (err.response) {
          switch (err.response.status) {
            case 404:
              this.showTips('请求发生404错误')
              break
            case 500:
              this.showTips('请求发生500错误')
              break
            case 504:
              this.showTips('请求超时')
              break
            case 400: // 用户没有csrf-token
            case 401: // 用户没有登录态
              if (!this.logining) { // 只使用第一次401的hash
                this.logining = true
                this.loginRedirect()
              }
              break
            case 403:
              this.showTips('您没有访问权限')
              break
            default:
              this.showTips('error:' + err.response.status)
              break
          }
          return Promise.reject(err.response.data)   // 返回接口返回的错误信息
        } else {
          // 跨域option请求的 err.response 会为空。增加该情况的错误处理
          this.showTips(`请求数据失败${err.message}`)
          return Promise.reject({ code: 500, message: err.message })
        }
      }
    )
  }

  public async loginRedirect () {
    const next = encodeURIComponent(window.location.href)
    const res = await rest.auth.login({
      next: next
    })
    if (res.code !== 0) {
      this.showTips(res.message)
      return
    }
    const weixinUrl = res.data
    window.location.href = weixinUrl
  }

  public query (path: string, config1 = { cache: false, baseUrl: '', schemaKey: '' }) {
    if (!this.queryMap[path]) { // cache path closure
      let url = ''
      this.queryMap[path] = (id, expand, config2?: AxiosRequestConfig) => {
        // 合并config
        const config = Object.assign({}, config1, config2)
        // 关闭缓存
        if (!config.cache) {
          const headers = config.headers = config.headers || {}
          headers['Cache-Control'] = 'no-cahce'
          headers['If-Modified-Since'] = '0'
        }
        if (expand) {
          url = path + '/' + expand
        } else {
          url = path
        }
        let newPath = url
        if (id) {
          newPath = this.parse(url, id)
        }
        const baseUrl = config.baseUrl || this.baseUrl
        return this.$http.get(baseUrl + newPath, config).then((res: AxiosResponse) => {
          return res.data
        }, (res: AxiosResponse) => {
          return res
        })
      }
    }
    return this.queryMap[path]
  }

  // 新增
  public create (path: string, config1 = { cache: false, baseUrl: '', schemaKey: '' }) {
    if (!this.createMap[path]) { // cache path closure
      let url = ''
      this.createMap[path] = (data, expand, config2) => {
        // 合并config
        const config = Object.assign({}, config1, config2)
        // 关闭缓存
        if (!config.cache) {
          const headers = config.headers = config.headers || {}
          headers['Cache-Control'] = 'no-cahce'
          headers['If-Modified-Since'] = '0'
        }
        if (expand) {
          url = path + '/' + expand
        } else {
          url = path
        }
        const baseUrl = config.baseUrl || this.baseUrl
        return this.$http.post(baseUrl + url, data, config).then((res: AxiosResponse) => {
          return res.data
        }, (res: AxiosResponse) => {
          return res
        })
      }
    }
    return this.createMap[path]
  }

  // put 方法~
  public putWay (path: string, config1 = { cache: false, baseUrl: '', schemaKey: '' }) {
    if (!this.putWayMap[path]) { // cache path closure
      let url = ''
      this.putWayMap[path] = (data, expand, config2 = {}) => {
        // 合并config
        const config = Object.assign({}, config1, config2)
        // 关闭缓存
        if (!config.cache) {
          const headers = config.headers = config.headers || {}
          headers['Cache-Control'] = 'no-cahce'
          headers['If-Modified-Since'] = '0'
        }
        if (expand) {
          url = path + '/' + expand
        } else {
          url = path
        }
        const baseUrl = config.baseUrl || this.baseUrl
        return this.$http.put(baseUrl + url, data, config).then((res: AxiosResponse) => {
          return res.data
        }, (res: AxiosResponse) => {
          return res
        })
      }
    }
    return this.putWayMap[path]
  }

  // patch 方法~
  public patch (path: string, config1 = { cache: false, baseUrl: '', schemaKey: '' }) {
    if (!this.patchMap[path]) { // cache path closure
      let url = ''
      this.patchMap[path] = (data, expand, config2 = {}) => {
        // 合并config
        const config = Object.assign({}, config1, config2)
        // 关闭缓存
        if (!config.cache) {
          const headers = config.headers = config.headers || {}
          headers['Cache-Control'] = 'no-cahce'
          headers['If-Modified-Since'] = '0'
        }
        if (expand) {
          url = path + '/' + expand
        } else {
          url = path
        }
        const baseUrl = config.baseUrl || this.baseUrl
        return this.$http.patch(baseUrl + url, data, config).then((res: AxiosResponse) => {
          return res.data
        }, (res: AxiosResponse) => {
          return res
        })
      }
    }
    return this.patchMap[path]
  }

  // delete方法
  public delete (path: string, config1 = { cache: false, baseUrl: '', schemaKey: '' }) {
    if (!this.deleteMap[path]) {
      let url = ''
      this.deleteMap[path] = (expand, config2 = {}): Promise<any> => {
        // 合并config
        const config = Object.assign({}, config1, config2)
        // 关闭缓存
        if (!config.cache) {
          const headers = config.headers = config.headers || {}
          headers['Cache-Control'] = 'no-cahce'
          headers['If-Modified-Since'] = '0'
        }
        if (expand) {
          url = path + '/' + expand
        } else {
          url = path
        }
        const baseUrl = config.baseUrl || this.baseUrl
        return this.$http.delete(baseUrl + url, config).then((res: AxiosResponse) => {
          return res.data
        }, (res: AxiosResponse) => {
          return res
        })
      }
    }
    return this.deleteMap[path]
  }

  // 设置用展示提示信息的函数
  public setTipFn (fn: (tips: string) => void): void {
    this.showTipsFn = fn
  }
  // 设置自定义的回调
  public setSuccess (success: (res: AxiosResponse) => void): void {
    this.success = success
  }

  private showTips (tips: string): void {
    this.showTipsFn(tips)
  }

  private parse (path: string, id: string | number | {[index: string]: string}) {
    if (typeof id === 'string') {
      return path + '/' + id
    }
    if (typeof id === 'object') {
      let search = '?'
      let counter = 0
      Object.keys(id).forEach((key: string) => {
        if (counter) {
          search += '&'
        }
        search += key + '=' + id[key]
        counter++
      })
      return path + search
    }
    return path
  }
}

export default new Ajax()
